.h-80vh {
  max-height: 80vh;
}

.createdAreasListing {
  position: absolute;
  right: 60px;
  top: 100px;
  width: 200px;
  max-height: 300px;
  overflow-y: scroll;
  background-color: #222;
  text-align: center;
  border-radius: 12px;
}
.createdAreasListing table,
.createdAreasListing tr,
.createdAreasListing td {
  border: 1px solid rgb(61, 61, 61);
}
.createdAreasListing table {
  width: 100%;
}
.createdAreasListing th,
.createdAreasListing td {
  padding: 0.75em;
}
