.roundedRow :first-child {
  border-top-left-radius: "12px";
}
.roundedRow :last-child {
  border-top-right-radius: "12px";
}

.RF_fileupload-input {
  width: 227px;
  height: 34px;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  padding-left: 2px;
  padding-top: 1px;
}

.RF_fileupload-input:focus {
  border: 1px solid #FF7200 !important;
  outline: 1px solid #FF7200 !important;
}
.RF_outline-btn {
  color: #455A64 !important;
  border: 1px solid #D9D9D9 !important;
  outline-color: #D9D9D9 !important;
}
.RF_outline-btn:hover {
  border: 1px solid #FF7200 !important;
  color: #FF7200 !important;
}
.RF_outline-btn:focus {
  color: #FF7200 !important;
  border: 1px solid #FF7200 !important;
}
.custom-mui-input label{
  top: -7px !important;
}
.custom-mui-input > label.Mui-focused{
  top: 0px !important;
}