.mui-orange-btn, .mui-orange-btn:hover {
    /* max-height: "32px"; */
    background-color: #FF7200 !important;
    color: white;
}
.Mui-focused {
	color: #FF7200 ;
}
.customDatePicker .Mui-Textfield{
    color: yellow; 
}
.customDatePicker .MuiSvgIcon-root{
    font-size: 1.1rem !important;
}
.tabsClass {
    font-weight: 800;
    color: #ff7200;
}
.customDatePicker .MuiFormLabel-root{ 
    font-size: 0.85rem !important;
    font-weight: 400 !important;
    /* color: rgba(0, 0, 0, 0.35); */
    box-shadow: none !important;
}

.mui-black-btn, .mui-black-btn:hover{
    background-color: black !important;
    color: white;
}

.mui-drawer-item {
    text-decoration: none;
}

.mui-custom-input{
    font-size: 10px;
}

.mui-custom-input::placeholder{
    font-size: 10px;
}
.theme-toggle{
    padding-left: 30px;
    padding-right: 30px;
}
.map-container{
    width: 100%;
    height: 73vh;
}
.location__search{
    width: 20%;
    height: 34px;
    position: absolute;
    top: 2em;
    border-radius: 8px;
    left: 25%;
    box-shadow: 11px 8px 7px rgb(0 0 0/9%);
    transform: translate(-50%, -50%); 
    transition: width 0.3s ease;
}
.location__search:focus-within {
    width: 24%; 
}
.mapInput{
    border-radius: 8px;
    width: 100%;
    height: 34px;
    border: 1px solid;
    padding-left: 0px;
    border-radius: 12px;
    font-size: 13px;
    padding-left: 10px;
    color: #808180;
}
.mapInput::placeholder{
    color: #7e7c7c;
    padding-left: 2px;
    font-size: 13px;
}
.mapInput:focus{
    outline: none;
}

.bottomPop{
    background-color: rgb(0 0 0/12%);
    width: auto;
    padding-left: 16px;
    padding-right: 16px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 6px;
    left: 45%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 0px 0px 10px rgba(224, 224, 224, 0.2);
    /* border: 2px solid rgb(0 0 0/10%); */
    border-bottom: none;
}
.bottomPop2{
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 6px;
    left: 45%;
}
.createGeoButtom{
    width: auto;
    height: 100%;
    background-color: #ff7200;
    color: #fff;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 7px;
    opacity: 1;
}
.cardOnMap{
    position: absolute;
    left: 10px;
    top: 10px;
    min-width: 260px;
    max-width: 260px;
    min-height: 160px;
    max-height: 350px;
    box-shadow: 2px 2px 30px #696969;
    border-radius: 4px;
    background-color: #fff;
}
.counterElem{
    width: 100%;
    height: 48px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 800;
    background-color: #ff7200;
}
.geoContainer{
    width: 100%;
    height: 84%;
    max-height: 300px;
    padding: 8px;
    overflow-y: auto;
}
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.geoBox{
    width: 100%;
    min-height: 100px;
    max-height: 110px;
    display: flex;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    flex-direction: column;
    border: 1px dotted rgb(0 0 0/16%);
    margin-bottom: 8px;
}
.geoBox:hover{
    background-color:  rgb(0 0 0/3%);
}
.drawer {
    position: absolute;
    top: 0;
    right: -30%; /* Initial position: off-screen */
    width: 30%;
    height: 100%;
    background-color: #fff;
    color: white;
    transition: right 0.4s;
    z-index: 100;
    box-shadow: 0px 0px 18px #A0A0A0;
}

.close-button {
    background-color: #E8E8E8;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: block;
    cursor: pointer;
    width: 100%;
}

#openDrawerBtn {
    margin: 20px;
}

#customDrawer.open {
    position: absolute;
    top: 0px;
    right: 0; /* Drawer is open */
}
#customDrawer.close {
    position: absolute;
    top: 0px;
    right: 0;
    width: 2% !important;/* Drawer is open */
}
.arrow-icon {
    margin-right: 5px; /* Adjust the margin as needed */
}
.circleElem{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff7700;
    cursor: pointer;
}
.drawer-toggle-floating-btn{
    padding: 10px;
    background: #FF7200;
    min-width: 50px;
    height: 40px;
    position: absolute;
    right: -20px;
    cursor: pointer;
    transition: all 3s ease-in-out;
    display: flex;
    align-items: center;
    border-radius: 50px 0 0 50px;
    z-index: 300;
}
.drawer-toggle-floating-btn .hide-text {
    display: none;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}
.drawer-toggle-floating-btn:hover{
    width: max-content;
    transition: all 0.3s ease-in-out;
}
.drawer-toggle-floating-btn:hover .hide-text {
    display: block;
    transition: all 0.3s ease-in-out;
}
/* ---------- */
.drawer-toggle-floating-btn--left{
    padding: 5px;
    background: #ff7300be;
    /* background: #FF7200; */
    min-width: 5px;
    height: 23px;
    position: absolute;
    /* right: -20px; */
    cursor: pointer;
    transition: all 3s ease-in-out;
    display: flex;
    align-items: center;
    border-radius: 0 50px 50px 0;
    z-index: 300;
}
.drawer-toggle-floating-btn--left .hide-text {
    display: none;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}
.drawer-toggle-floating-btn--left:hover{
    width: max-content;
    transition: all 10.3s ease-in-out;
    background: #FF7200;
    padding: 5px 8px;
}
.drawer-toggle-floating-btn--left:hover .hide-text {
    display: block;
    transition: all 0.3s ease-in-out;
}

.drawMap .gm-fullscreen-control {
    position: absolute;
    left: 0px;
    top: 50px !important;
  }

  .tooltip-container {
    position: relative;
    display: inline-block;
}

/* Tooltip content */
.tooltip-content {
    visibility: hidden;
    background-color: rgb(117, 107, 107);
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 3px 8px;
    position: absolute;
    z-index: 1;
    bottom: 155%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    font-size: 11px;
    font-weight: 400;
    transition: opacity 0.2s;
}

/* Tooltip arrow */
.tooltip-content::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(117, 107, 107) transparent transparent transparent;
    transform: translateX(-50%);
}

/* Show tooltip on hover */
.tooltip-container:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
}

.outlined-theme-btn{
    min-width: 35px;
    width: max-content;
    height: 30px;
    padding: 3px 7px;
    border-radius: 11px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin: auto;
    border: 1px solid #ff7200;
    color: #ff7200;
    background-color: transparent;
    margin-top: 3px;
}

.outlined-theme-btn:hover{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}
.outlined-theme-btn-add-ons{
    border: 1px solid #BEBEBE;
    color: #BEBEBE;
}

.filled-theme-btn{
    min-width: 35px;
    width: max-content;
    height: 30px;
    padding: 3px 7px;
    border-radius: 11px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin: auto;
    border: 1px solid #ff7200;
    color: #ffffff;
    background-color: #ff7200;
    margin-top: 3px;
}
.filled-theme-btn:hover{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.passcode-val-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 3px;
    width: max-content;
    /* height: 30px; */
    padding: 3px 7px;
    border-radius: 11px;
    border: 1px solid #ff7200;
    color: #ff7200;
}

.viapointContainer{
    width: 46px;
    /* height: 23px; */
    padding: 3px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: rgb(130, 120, 120);
    font-size: 12px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}
.viapointContainerActive{
    width: 46px;
    /* height: 23px; */
    padding: 3px;
    border-radius: 7px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-size: 12px;
    background-color: #ff7200;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}
.addressCals{
    max-width: 220px;
    flex-wrap: wrap;
    white-space: initial;
    cursor: pointer;
    color: #ff7200;
}
.max-width-table-cell{
    max-width: 220px;
    display: flex;
    flex-wrap: wrap;
    white-space: initial;
}
.circleIcon-outlined{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: transparent;
    color: #ff7200;
    cursor: pointer;
    font-size: 12px ;
    padding: 4px;
}
.circleIcon{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #696969;
    color: #fff;
    cursor: pointer;
    font-size: 12px ;
    padding: 4px;
}
.verticalClass{
    display: flex;
    text-align: center;
    justify-content: center;
}
.color-chip{
    width: max-content;
    text-align: center;
    word-wrap: break-word;
    border-radius: 20px;
    padding: 2px 7px;
    cursor: pointer;
    font-size: 12.5px;
    margin-left: auto;
    margin-right: auto;
}
.sNoBox{
    position: absolute;
    top: 0px;
    right: 0px;
    width: 24px;
    height: 24px;
    color: #fff;
    display: flex ;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #696969;
}
.addIcon{
    color: green;
    cursor: pointer;
}
.removeIcon{
    cursor: pointer;
    color: #696969;
}
.addGreenBtn{
    position: absolute !important;
    top: 7px !important;
    right: 5px !important;
    z-index: 10;
}
.deleteredBtn{
    position: absolute !important;
    top: 7px !important;
    right: 110px !important;
}
.indexBox{
    position: relative;
    top: 3px;
    left: 4px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #696969;
    color: #fff;
    display: flex;
    text-align: center;
    font-size: 11px !important;
    justify-content: center;
    align-items: center;
}
.driverDrop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 14px !important;
    margin-bottom: 6px !important;
}
.devicesBox{
    display: flex;
    justify-content: space-around;
}
.devicesBoxSno{
    display: flex;
    justify-content: flex-end;
}
.fileUpload {
    position: absolute;
    cursor: pointer !important;
    top: 0;
    left: 0;
    opacity: 0; /* Hide the input */
    width: 100%;
    height: 100%;
}

.imageContainer {
    min-width: 100px;
    height: 140px;
    display: flex;
    position: relative;
    align-items: center;
    overflow: auto; 
}
.imageBox {
    width: 100px;
    min-width: 80px; 
    height: 80%;
    border: 1px solid #dcdada;
    flex-shrink: 0; 
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.imgFile{
    width:70%;
    height: 70%;
    cursor: pointer !important;
}
.cancelIcon{
    position: absolute;
    top: -11px;
    font-size: 8px;
    width: 18px !important;
    height: 18px !important;
    display: flex;
    right: -9px;
    background-color: transparent;
    color : #696969;
    cursor: pointer;
}
.zoomImage{
    position: absolute;
    bottom: 0px; 
    right: 0px;
    color : #696969;
    background-color: transparent;
    width: 15px !important;
    height: 15px !important;
    cursor: pointer !important;
}
.downloadIcon{
    position: absolute;
    bottom: 0px; 
    left: 0px;
    color : #696969;
    background-color: transparent;
    width: 15px !important;
    height: 15px !important;
    cursor: pointer !important;
}
.driverModal{
    position: absolute;
    bottom: 114px;
    overflow: visible;
    z-index: 99;
    width: 28%;    
}
.close{
    display: none;
}
.driverModal::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 3%; 
    border-width: 8px 8px 0 8px;
    border-style: solid;
    border-color: #A9A9A9 transparent transparent transparent;
}
.viaNumber{
    position: absolute;
    top: -10px;
    left: -10px;
    border-radius: 50%;
    background-color: #696969;
    color: #fff;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
}
.trip_tripId{
    font-size: 12px;
    font-weight: 800;
    cursor: pointer;
}
.geofence__map-resize-icon {
    position: absolute;
    right: 3px;
    top: 5px;
    height: 2rem;
    width: 2rem;
    cursor: pointer;
    border-radius: 4px;
}
.location__search_monitoring {
    position: absolute;
    top: 5px;
    left: 5px;
    /* background-color: whitesmoke; */
    display: flex;
    align-items: center;
    gap: 3px;
    border-radius: 4px;
    border: none;
    z-index: 2;
  }
  
  .location__search_monitoring > svg {
    position: absolute;
    top: 7px;
    left: 4px;
  }
  .location__search_monitoring > input {
    padding-left: 3em;
    padding-right: 1.75em;
    height: 36px;
    width: 250px;
    border-radius: 4px;
    border: none;
  }
  .location__search_monitoring > input:focus {
    outline: 1px solid gray;
  }
  .location__search_monitoring__cancel {
    color: gray;
    position: absolute;
    top: 8px;
    right: 10px;
    cursor: pointer;
    transition: all 0.1s ease-in;
  }
  .location__search_monitoring__cancel.location__search_monitoring__cancel:hover {
    scale: 1.1;
  }
  .parentModal::-webkit-scrollbar {
    display: none;
}
.spinner {
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
  }
  
  .spinner > div {
    width: 13px;
    height: 13px;
    background-color: #ff7200;
  
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  
  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  
  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  
  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bouncedelay {
    0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }

.idclass{
    color: #ff7200;
    cursor: pointer;
    font-size: 13px;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 4px;
}
.withoutRoute_minDis{
    color: #696969;
    font-size: 13px;
    position: relative;
    top: 2px;
}
.infoDiv{
    width: 500px;
    display: flex;
    flex-direction: column;
}
.infoChild1{ 
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 12px;
    margin-top: 10px;
}
.gm-style .gm-style-iw-c{
    /* box-shadow: none; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.subChild{
    width:48%;
    height: 100%;
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-right: 6px;
    box-shadow: 2px 2px 5px #AEAEAE;
}
.bgGreen{
    background-color: green;
    padding: 5px;
}
.bgGray{
    background-color: #696969;
    padding: 5px;
}
.bgblue{
    background-color: #6495ED;
    padding: 5px;
}
.lockImg{
    width: 27px;
    height: 25px;
}
.imgCss{
    width: 28px;
    height: 25px;
}
.infoLabel{
    color: #000;
    font-size: 13px;
    font-weight: 400;
}
.deviceLabel{
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}
.devicevalue{
    color: #fff;
    font-weight: 400;
}
.infoValue{
    color: #ff7200;
    font-weight: 400;
}
.infoChild2{
    width: 100%;
    height: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 4px;
}
.info-content {
    background-color: red;
    /* Additional styling for your info window content */
  }
.subChild2{
    width: 25%;
    flex-wrap: wrap;
    padding-left: 4px;
    padding-right: 4px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-right: 2px dashed #000;
    overflow-wrap: anywhere;
}
.subChild2:last-of-type{
    border-right: 0px dashed #fff;
}
.label2{
    font-weight: 700;
    height: 20px;
    display: flex;
    align-items: center;
}
.infoChild3{
    width: 100%;
    height: 60px;
    display: flex;
}
.infoValue2{
    color: #000;
    height: 30px;
    display: flex;
    align-items: center;
}
.addressDiv{
    width: 100%;
    display: flex;
}
.addressImg{
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.addressBox{
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
}
.chart-box > canvas {
    width: 100% !important;
    object-fit: contain;
}
.tripIdBox{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    color: #fff;
    border-radius: 6px;
}
.boxVertical{
    margin-top: 6px;
}
.imgSize{
    width: 25px;
    height: 20px;
}
.imgSize-sm{
    width: 20px;
    height: 15px;
}
.addressContainer{
    max-width: 130px;
    flex-wrap: wrap;
    white-space: initial;
    color: #808080;
}
.ring {
    width: 200px;
    height: 200px;
    border: 20px solid #d4d4d4; /* Adjust the border thickness as needed */
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
.closureIcon{
    border: 1px solid red;
    /* width: 20px; */
    height: auto;
    padding: 5px
}
.confirmation-toast {
    border: 1px solid #555;
    border-radius: 8px;
    /* color: var(--clr--darkblue); */
    text-transform: uppercase;
    padding: 1em;
  
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 0.75em;
    /* width: 20em; */
  }
  
  .confirmation-toast_btnWrapper {
    display: flex;
    gap: 0.5em;
  }
  .confirmation-toast-btn {
    border-radius: 8px;
    padding: 0.25em 1em;
    cursor: pointer;
    transition: all 0.1s ease-out;
  }
  .confirm-toast-message {
    background: transparent;
    padding: 0px;
    border: 1px solid #696969;
  }
  .statusBox{
    display: flex;
    max-width: 250px;
    margin: auto;
    justify-content: center;
    align-items: center;
  }
.statusSpan{
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    white-space: initial;
}
.flex-row-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.small-custom-loader {
    border: 2.2px solid #bbbbbb;
    border-radius: 50%;
    border-top: 2.2px solid #ff7200;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


  /* **************Home screen css*********** */

  /* .container-fluid {
  padding-right: 0px;
  padding-left: 0px;
} */

/* @font-face {
  font-family: Phenomena-Regular;
  src: url("../../../assets/fonts/Phenomena-Regular.ttf") format("TrueType")
}

@font-face {
    font-family: "Fjalla One", sans-serif;

  src: url("../../../assets/fonts/Phenomena-Bold.ttf") format("TrueType")
}

@font-face {
  font-family: Montserrat-bold;
  src: url("../../../assets/fonts/Montserrat-Bold.ttf") format("TrueType")
}


@font-face {
  font-family: Montserrat-regular;
  src: url("../../../assets/fonts/Montserrat-Regular.ttf") format("TrueType")
} */

.nav-item {
  font-family: "Fjalla One", sans-serif;

  font-size: x-large;
}


.welcome-txt {
  position: absolute;
  top: 20%;
  left: 14%;
  font-size: 60px;
  color: #ff7800;
  font-weight: bold;



}

.wlcm {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 20%;
  display: flex;
  justify-content: space-around;
}
.wlcm_ {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 12%;
  display: flex;
  justify-content: space-around;
  z-index:99999
}
.wlcm1{
   letter-spacing: 3px !important;
  line-height: 1.2;
  font-size: 65px;
  color: #ff7800; 
  font-weight: 0;
}

.wlcm2{
  font-family: "Fjalla One", sans-serif !important;
  letter-spacing: 0px !important;
  line-height: 1.2;
  font-size: 70px;
  color: #ff7800; 
  font-weight: bold;
}

.inputdes {
  padding: 0rem 1rem;
  margin-bottom: 0rem;
}

.forgotpass {
  font-size: x-small;
  display: flex;
  justify-content: end;
  margin: 3vh 2vh 0vh 2vh;
  color: grey;
  letter-spacing: 0px !important;

}


.forgotpass:hover {
  cursor: pointer;
  color: rgb(75, 74, 74);
}

.btnbox {
  display: flex;
  justify-content: center;
}

.btnreg {
  width: 50%;
  color: grey;
  border: 1px solid #ff7800;
  margin: 3vh 2vh 5vh 2vh
}

.btnsub {
  width: 100%;
  margin: 3vh 2vh 5vh 2vh
}

#myVideo, #myVideo1 {
  position: relative;
  right: 0;
  bottom: 0;
  height: 80vh;
  width: 100%;
  object-fit: fill;
  top:70px;
}

.osahan-navbar {
  background-color: black;
}

section {
  padding: 0;
}

.section-one {
  width: 100%;
  height: 100vh;
  /* background-image: url('/assets/landing/img/bg1.gif'); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

.section-two {
  width: 100%;
  height: 100vh;
}


.section-three {
  width: 100%;
  height: 100vh;
  /* background-image: url('/assets/landing/img/group.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed; */
}


h1, h2, h3, h4, h5, h6 {
  font-family: "Fjalla One", sans-serif;
  letter-spacing: 0px !important;

}


.logo {
  width: 144px;
}

.banner-contact {
  background: white;
  border-radius: 10px;
  margin-top: -60px;
}

.nav1 {
  padding: 0 3rem;
  margin-right: 8px;
  /* margin-right: 1.5rem; */
}

.form-group {
  padding: 0.5rem 1rem;
}

input {
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}

.osahan-navbar .nav-link {
  font-size: 24px;
  font-family: "Fjalla One", sans-serif !important;
  font-weight: bolder;
}

.welcome-section {
  width: 50%;
  text-align: inherit;
  padding-left: 4rem;
}

.welcome-text {
  color: white;
  font-weight: bold;
  font-size: 72px;
  font-family: "Fjalla One", sans-serif !important;
  line-height: 1.2;
}

.welcome-login {
  width: 180px;
  background-color: #fff;
  border: medium none;
  border-radius: 100px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  color: #3f345f;
  height: 48px;
  font-size: 24px;
  font-family: "Fjalla One", sans-serif !important;
  margin-top: 24px;
}

.welcome-register {
  width: 180px;
  border: 1px solid white;
  border-radius: 100px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  height: 48px;
  font-size: 24px;
  font-family: "Fjalla One", sans-serif !important;
  margin-top: 24px;
  background: #ff7800;
  color: white;
  margin-right: 24px;
}
.aHover:hover{
  cursor: pointer;
}
.feature-left-inner {
  margin-top: 10rem;
}

.feature-head {
  padding: 1rem 0rem 1rem 8rem;
  font-family: "Fjalla One", sans-serif;

  font-size: 30px;
  font-weight: 900;


  cursor: pointer;
}

.feature-head:hover {
  color: #ff7800;
}

.feature-head-active {
  font-size: 36px;
  color: #ff7800;
}

.feature-arrow {
  margin-left: 5rem;
  margin-right: 0rem;
  opacity: 0;
  margin-top: 2rem;
  font-size: 16px;
  color: #ff7800;
}

.feature-arrow-active {
  opacity: 1;
}

.feature-detail {
  text-align: justify;
  font-size: 14px;
  margin-left: 6vh;
}

.feature-img {
  width: 85%;
  margin-top: -60px
}

.feature-border {
  /* height: 64%;
    margin-top: -24px;
     */
  height: 96%;

  position: absolute;
  /* right: 0; */
}


.feature-border1 {
  /* height: 64%;
    margin-top: -24px;
     */
  height: 80%;

  position: absolute;
  /* right: 0; */
}

.d-none {
  display: none;
}

.d-show {
  display: initial !important;
}


.img-partner {
  width: 162px;
  height: 162px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}



.img-partner1 {
  /* width: 162px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer; */

  background-repeat: no-repeat;
  background-size: contain;
  width: 20% !important;
  height: 164px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.b1 {
  background-image: url('../../assets/landing/img/our_partner/1_g.jpg');
}

.b2 {
  background-image: url('../../assets/landing/img/our_partner/2_g.jpg');
}

.b3 {
  background-image: url('../../assets/landing/img/our_partner/3_g.jpg');
}

.b4 {
  background-image: url('../../assets/landing/img/our_partner/4_g.jpg');
}

.b5 {
  background-image: url('../../assets/landing/img/our_partner/5_g.jpg');
}

.b6 {
  background-image: url('../../assets/landing/img/our_partner/6_g.jpg');
}

.b7 {
  background-image: url('../../assets/landing/img/our_partner/7_g.jpg');
}

.b8 {
  background-image: url('../../assets/landing/img/our_partner/8_g.jpg');
}

.b9 {
  background-image: url('../../assets/landing/img/our_partner/9_g.jpg');
}

.b10 {
  background-image: url('../../assets/landing/img/our_partner/10_g.jpg');
}

.b1:hover {
  background-image: url('../../assets/landing/img/our_partner/1_c.jpg');
}

.b2:hover {
  background-image: url('../../assets/landing/img/our_partner/2_c.jpg');
}

.b3:hover {
  background-image: url('../../assets/landing/img/our_partner/3_c.jpg');
}

.b4:hover {
  background-image: url('../../assets/landing/img/our_partner/4_c.jpg');
}

.b5:hover {
  background-image: url('../../assets/landing/img/our_partner/5_c.jpg');
}

.b6:hover {
  background-image: url('../../assets/landing/img/our_partner/6_c.jpg');
}

.b7:hover {
  background-image: url('../../assets/landing/img/our_partner/7_c.jpg');
}

.b8:hover {
  background-image: url('../../assets/landing/img/our_partner/8_c.jpg');
}

.b9:hover {
  background-image: url('../../assets/landing/img/our_partner/9_c.jpg');
}

.b10:hover {
  background-image: url('../../assets/landing/img/our_partner/10_c.jpg');
}

.b11 {
  background-image: url('../../assets/homeimg/img1_samsung.jpg');
  filter: grayscale(100%);
}

.b11:hover {
  filter: grayscale(0%);
  cursor: pointer;
}

.b12 {
  background-image: url('../../assets/homeimg/img2_group.jpg');
  filter: grayscale(100%);
}

.b12:hover {
  filter: grayscale(0%);
  cursor: pointer;
}

.b13 {
  background-image: url('../../assets/homeimg/bharat_petroleum.jpg');

  filter: grayscale(100%);
}

.b13:hover {
  filter: grayscale(0%);
  cursor: pointer;

}

.b14 {
  background-image: url('../../assets/homeimg/img4_dixon.jpg');
  filter: grayscale(100%);
}

.b14:hover {
  filter: grayscale(0%);
  cursor: pointer;

}


.b15 {
  background-image: url('../../assets/homeimg/img5_sleepwell.jpg');
  filter: grayscale(100%);
}

.b15:hover {
  filter: grayscale(0%);cursor: pointer;

}

.b16 {
  background-image: url('../../assets/homeimg/img6_blueDart.jpg');
  filter: grayscale(100%);
}

.b16:hover {
  filter: grayscale(0%);
  cursor: pointer;

}

.b17 {
  background-image: url('../../assets/homeimg/img7_delhivery.jpg');
  filter: grayscale(100%);
}

.b17:hover {
  filter: grayscale(0%);
  cursor: pointer;

}

.b18 {
  background-image: url('../../assets/homeimg/mahindraLogistics.png');
  filter: grayscale(100%);
}

.b18:hover {
  filter: grayscale(0%);
}

.b19 {
  background-image: url('../../assets/homeimg/img8_landmark.jfif');
  filter: grayscale(100%);
}

.b19:hover {
  filter: grayscale(0%);
  cursor: pointer;

}


.b20 {
  background-image: url('../../assets/homeimg/img9_flipkart.jpg');
  filter: grayscale(100%);
}

.b20:hover {
  filter: grayscale(0%);
  cursor: pointer;

}

.b21 {
  background-image: url('../../assets/homeimg/img10_pepfules.png');
  filter: grayscale(100%);
}

.b21:hover {
  filter: grayscale(0%);
  cursor: pointer;

}

.b22 {
  background-image: url('../../assets/homeimg/img11_adiyabirla.jpg');
  filter: grayscale(100%);
}

.b22:hover {
  filter: grayscale(0%);
  cursor: pointer;

}

.b23 {
  background-image: url('../../assets/homeimg/img13_cmacga.jfif');
  filter: grayscale(100%);
}

.b23:hover {
  filter: grayscale(0%);
  cursor: pointer;

}

.b24 {
  background-image: url('../../assets/homeimg/img12_lg.jpg');
  filter: grayscale(100%);
}

.b24:hover {
  filter: grayscale(0%);
  cursor: pointer;

}

.b25 {
  background-image: url('../../assets/homeimg/img14_ADB.png');
  filter: grayscale(100%);
}

.b25:hover {
  filter: grayscale(0%);
  cursor: pointer;

}

/* The width of each slide
.slick-slide {
  width: 350px;
}

.slick-list.draggable {
  padding: 0px 10px !important;
}

.slick-slide {
  width: 245px !important;
}
*/

.slick-track {
  margin-left: 36px !important;
}

.product-item img {
  width: 100px;
  margin: auto;
  filter: drop-shadow(2px 6px 9px rgba(0, 0, 0, 0.918));
}


#features {
  padding-bottom: 0px;
  scroll-margin-top: 50px;
}

#features .feature-border {
  /* margin-top: -24px; */
  margin-top: -10px;
}


#features .feature-border1 {
  /* margin-top: -24px; */
  margin-top: -10px;
}

/* #features-1 .feature-border {
  bottom: 24px;
} */

#hardware {
  margin-top: 72px;
  margin-bottom: 36px;
  position: relative;
  scroll-margin-top: 15px;
}

#hardware-image {
  width: 100%;
}


.circle {
  border-radius: 50%;
  background: white;
  position: absolute;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  /* box-shadow: 0 0 10px 4px #00000085; */
}

.small-cirlce {
  width: 200px;
  height: 200px;
  cursor: pointer;
  z-index: 1;
}

.big-circle {
  width: 360px;
  height: 360px;
  top: 36%;
  left: 36%;
  text-align: center;
  line-height: 180px;
  z-index: 1;
}

.big-circle-text {
  font-size: 54px;
  color: #ff7800;
  /* top: 127px;
  left: 38px; */
  top: 22%;
  /* left: 56px; */
  font-family: "Fjalla One", sans-serif;

  text-shadow: 1px 1px 3px #000000cf;
  position: relative;
}

.rfid-circle {
  top: 10%;
  right: 36%;
  position: absolute;
}

.rfid-tag-circle {
  top: 36%;
  right: 27%;
  position: absolute;
}

.bt-circle {
  top: 66%;
  right: 20%;
  position: absolute;
}

.wrist-circle {
  top: 76%;
  right: 50%;
  position: absolute;
}

.asset-circle {
  top: 75%;
  left: 8%;
  position: absolute;
}

.fixed-circle {
  top: 42%;
  left: 6%;
  position: absolute;
}

.btntop{
  margin-top:5vh;
  display: flex;
  justify-content: center;
}

.btntop1{
  margin: 1vh;
  padding: 2vh;
  width: 20%;
}
.elock-circle {
  top: 8%;
  left: 22%;
  position: absolute;
}

.toastBtnBox{
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
}
.Toastify--animate-icon  {
  display: none;
}
.toastMessageStyle{
  padding-bottom: 11px;
  color: #686868;
  font-weight: 800;
  font-family: Nunito, serif;
  font-size: 16px !important;
}
.refresh{
  width: 50%;
  border-radius: 5px;
  height: 28px;
  border: none;
  font-size: 13px;
  background-color: #ff7200;
  color: #fff;
  font-weight: 500;
  transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}
.refresh:hover{
  background-color: #fff;
  color: #ff7200;
  border: 1px solid #ff7200;
  font-weight: 700;
}
.cancel{
  width: 50%;
  border-radius: 5px;
  height: 28px;
  border: none;
  font-size: 13px;
  background-color: red;
  color: #fff;
  margin-left: 7px;
  font-weight: 500;
  border: 1px solid red;
  transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
}
.cancel:hover{
  background-color: #fff;
  color: red;
  font-weight: 700;
  border: 1px solid red;
}
.toastBtnbox{
  display: flex;
  justify-content: center;
}
@keyframes smoothExit {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px); 
  }
}

.Toastify__bounce-exit--bottom-center {
  animation: smoothExit 0.3s ease forwards; 
}
.Toastify__toast-container--bottom-center {
  bottom: -10px !important;
}
.rfid-line {
  width: 8px;
  top: 127px;
  transform: rotate(36deg) translateY(18px) translateX(-36px);
  background: white;
  position: absolute;
  box-shadow: none;
  /* height: 362px; */
}

.rfid-tag-line {
  width: 8px;
  top: 127px;
  transform: rotate(75deg) translateY(26px) translateX(-108px);
  background: white;
  position: absolute;
  box-shadow: none;
  /* height: 362px; */
}

.bt-line {
  width: 8px;
  top: 127px;
  transform: rotate(116deg) translateY(79%) translateX(-236px);
  background: white;
  position: absolute;
  box-shadow: none;
  /* height: 362px; */
}

.wrist-line {
  width: 8px;
  top: 127px;
  transform: rotate(-18deg) translateY(-290px) translateX(122px);
  background: white;
  position: absolute;
  box-shadow: none;
  /* height: 362px; */
}

.asset-line {
  width: 8px;
  top: 127px;
  transform: rotate(48deg) translateY(-100%) translateX(-182px);
  background: white;
  position: absolute;
  box-shadow: none;
  /* height: 362px; */
}

.fixed-line {
  width: 8px;
  top: 127px;
  transform: rotate(-88deg) translateY(331px) translateX(198px);
  background: white;
  position: absolute;
  box-shadow: none;
  /* height: 362px; */
}

.elock-line {
  width: 8px;
  top: 127px;
  transform: rotate(-32deg) translateY(80px) translateX(198px);
  background: white;
  position: absolute;
  box-shadow: none;
  /* height: 362px; */
}

#rfid-img {
  /* margin-top: 3rem;
  margin-left: 1rem; */
  margin-top: 4.2rem;
  margin-left: 2.5rem;
  filter: drop-shadow(2px 6px 9px rgba(0, 0, 0, 0.918));
  width: 128px;
}

#rfid-tag-img {
  /* margin-top: 75px;
  margin-left: 16px; */
  margin-top: 99px;
  margin-left: 36px;
  filter: drop-shadow(2px 6px 9px rgba(0, 0, 0, 0.918));
  width: 128px;
}

#bt-img {
  /* margin-top: 16px;
  margin-left: 46px;*/
  margin-top: 32px;
  margin-left: 60px;
  filter: drop-shadow(2px 6px 9px rgba(0, 0, 0, 0.918));
  height: 65%;
}

#wrist-img {
  /* margin-top: 12px;
  margin-left: 48px; */
  margin-top: 30px;
  margin-left: 69px;
  filter: drop-shadow(2px 6px 9px rgba(0, 0, 0, 0.918));
  height: 70%;
}

#asset-img {
  /* margin-top: 28px;
  margin-left: 20px; */
  margin-top: 36px;
  margin-left: 30px;
  filter: drop-shadow(2px 6px 9px rgba(0, 0, 0, 0.918));
  height: 66%;
}

#fixed-img {
  margin-top: -4px;
  /* margin-left: 42px; */
  margin-left: 56px;
  filter: drop-shadow(2px 6px 9px rgba(0, 0, 0, 0.918));
  height: 84%;
}

#elock-img {
  /* margin-top: 7px;
  margin-left: 54px; */
  margin-top: 24px;
  margin-left: 72px;
  filter: drop-shadow(2px 6px 9px rgba(0, 0, 0, 0.918));
  /* height: 88%; */
  height: 70%;
}

.img-text {
  width: 208px;
  position: absolute;
  color: white;
  font-family: "Fjalla One", sans-serif;

  font-size: 20px;
  top: -36px;
  left: 36px;
}

/* 
.rfid-text {}

.rfid-tag-text {}

.bt-text {} */

.wrist-text {
  /* top: 156px; */
  top: 200px;
  left: 64px;
}

.asset-text {
  /* top: 156px; */
  top: 200px;
  left: 64px;
}

/* .fixed-text {} */

.cirdiv {
  width: 250px;
  position: absolute;
  top: 70px;
  margin-left: 50px;
  text-align: justify;
}

/* .elock-text {} */

/* 
#text-1
{
  margin-top: 108px;
  position: absolute;
  text-align: justify;
  margin-left: 40px;
  margin-right: 40px;
}
#text-2
{
  margin-top: 80px;
  position: absolute;
  text-align: justify;
  margin-left: 44px;
  margin-right: 44px;
} */
/* #text-3
{
  margin-top: 80px;
  position: absolute;
  text-align: justify;
  margin-left: 44px;
  margin-right: 44px;
}
#text-4
{
  margin-top: 80px;
  position: absolute;
  text-align: justify;
  margin-left: 44px;
  margin-right: 44px;
}
#text-5
{
  margin-top: 80px;
  position: absolute;
  text-align: justify;
  margin-left: 44px;
  margin-right: 44px;
}
#text-6
{
  margin-top: 56px;
  position: absolute;
  text-align: justify;
  margin-left: 55px;
  margin-right: 48px;
}
#text-7
{
  margin-top: 108px;
  position: absolute;
  text-align: justify;
  margin-left: 40px;
  margin-right: 40px;
} */

.btnn{
  background-color: #ff7800;
  color: white;
}
.para {
  line-height: 22px !important;
  font-size: 12px;
}

.background {
  padding-top: 50px;
  background: gray;
}


.customer1 {
  height: 100vh;
}

.row1, .row2 {
  padding-left: 0px;
  padding-right: 0px;
}

.heading-div .row h1 {
  font-size: 44px;
  color: #000;
  text-shadow: none;
  font-weight: 900;
  font-family: "Fjalla One", sans-serif;
  background-image: linear-gradient(#ff7800, #ff7800);
  background-repeat: no-repeat;
  line-height: 110px;
  background-size: 0 4px;
  background-position: bottom;
  letter-spacing: 0 !important;
}

.row2 .row {
  width: 95%;
  height: 178px;
  display: flex;
  align-items: center;
  justify-content: center !important;
}



.div-size1 {
  padding: 5px;
  width: 12% !important;
  height: 164px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 4px 10px grey;
}

.div-size {
  padding: 5px;
  width: 12% !important;
  height: 164px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.div-size img {
  filter: grayscale(100%);
}


.div-size1 img {
  filter: grayscale(100%);
}


.div-size1 img:hover {
  filter: grayscale(0%);
  cursor: pointer;
}

.div-size img:hover {
  filter: grayscale(0%);
  cursor: pointer;
}




.customer_images1 {
  width: 130px;
}

.customer_images2 {
  width: 125px;
}

.heading-div1 .row #more_3500, #more_3500 h1 {
  align-items: center;
  justify-content: center;
  display: flex;

}

#more_3500 {
  width: 100%;
  height: 66px;
  /* top: 628px; */
}

#more_3500 h1 {
  font-size: 46px;
  color: #424141;
  text-align: center;
  font-weight: 700;
  font-family: "Fjalla One", sans-serif;

  background-image: linear-gradient(#fff, #fff);
  background-repeat: none;
  background-size: 0 0;
  filter: grayscale(100%);
}


#more_3500 h1 :hover {

  filter: grayscale(0%);
  cursor: pointer;
}

#more_3500 h1:hover {
  color: #000;
}

#bharat1 {
  width: 110px;
  height: 130px;
}

#pepfules {
  width: 127px;
  height: 60px;
}

.row-margin {
  display: flex;
  justify-content: space-around;
  margin: 0rem 2rem;
}

.customers_div_2 div {
  width: 14% !important;
  height: 178px;
  display: flex;
  justify-content: center;
  box-shadow: 4px 4px 10px grey;
}

.customer_images {
  width: 130px;
  /* height: 100px; */
}


.customer_div1 {
  height: 900px;
  top: 2494px;
  display: -webkit-flex;
  display: flex;
}



footer {
  padding: 10px 50px;
  border: none;
  background: white;
}

@media only screen and (max-resolution: 120dpi) {
  .rfid-line {
    height: 362px;
  }

  .rfid-tag-line {
    height: 200px;
  }

  .bt-line {
    height: 368px;
  }

  .wrist-line {
    height: 220px;
  }

  .asset-line {
    height: 634px;
  }

  .fixed-line {
    height: 362px;
  }

  .elock-line {
    height: 340px;
  }
}


@media only screen and (max-resolution: 166dpi) and (min-resolution: 120dpi) {

  .big-circle {
    width: 500px;
    height: 500px;
    top: 33%;
    left: 33%;
  }

  .big-circle-text {

    font-size: 68px;

  }

  #text-1 {
    margin-top: 200px;
  }

  #text-2 {
    margin-top: 176px;
  }

  #text-3 {
    margin-top: 176px;
  }

  #text-4 {
    margin-top: 176px;
  }

  #text-5 {
    margin-top: 176px;
  }

  #text-6 {
    margin-top: 170px;
  }

  #text-7 {
    margin-top: 200px;
  }

  .para {
    line-height: 22px !important;
    font-size: 15px;
  }
}

.xs-none {
  /* visibility: visible !important; */
  display: flex !important;
}

.lg-none {
  display: none !important;
}

.part {
  margin-top: 0px;
}

.cont {
  width: 85%;
  margin-bottom: 0px;
}


.d1 {
  width: 21%;
}

.d2 {
  width: 27%;
}

.d3 {
  padding: 36px;
}

.a-l {
  font-size: 16px;
}

.a-l:hover {
  color: whitesmoke;
  font-size: 20px;
}

#blindspotMap {
  width: 600px;
  height: 380px;
  border-radius: 10px;
  margin-inline-start: 24px;
}

.address {
  padding-left: 5rem;
  padding-top: 1rem;
}

.call {
  padding-left: 5rem;
  padding-top: 0.5rem;
}

.mail {
  padding-left: 5rem;
  padding-top: 0.5rem;
}

.google {
  padding-left: 5rem;
  padding-top: 0.5rem;
}

.apple {
  padding-left: 5rem;
  padding-top: 0.5rem;
}

.img-d3 {
  border-radius: 10px;
  margin-top: 44px;
  width: 100%;
}

.contact_us {
  padding-left: 5rem;
  padding-top: 5rem;
}

.d-check {
  display: flex
}

.cookieAcceptBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #ff7800;
  color: #fff;
  padding: 5px 0;
  z-index: 99999;
}

#cookieAcceptBarConfirm {
  height: 20px !important;
  line-height: 0 !important;
  font-size: 12px;
  margin-left: 10px;
  background: white;
  border: 1px solid white;
}

#cookieAcceptBarConfirm1 {
  height: 20px !important;
  line-height: 0 !important;
  font-size: 12px;
  margin-left: 10px;
  background: white;
  border: 1px solid white;
}

.cookieAcceptBar p {
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 14px;
  text-align: justify;
  margin-bottom: 5px;
}


#touch {
  background-color: #ff7800;
  height: 156px;
}

.btn-orange {
  background-color: #ff7800;
  color: white;
}
.btn-orange:disabled {
  background-color: #808080;
  color: white;
}

.stay {
  padding-top: 1rem;
  padding-left: 5rem;
  font-weight: bolder;
}

.trial-text {
  font-size: 18px;
  width: 200px;
  background-color: #fff;
  border: medium none;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
  color: #3f345f;
  height: 36px;
  font-family: "Fjalla One", sans-serif;

  margin-left: 5rem;
}

@media only screen and (min-width: 1600px) {
  .big-circle {
    width: 900px;
    height: 900px;
    top: 27%;
    left: 23%;
  }

  .nxt{
    width:10%
  }

  .para {
    line-height: 40px !important;
    font-size: 20px;
  }

  .cirdiv {
    position: absolute;
    width: 500px;
    top: 0px;
    margin-left: 185px
  }

  .wrist-circle {
    top: 85%
  }

  .big-circle-text {
    font-size: 130px;
    top: 300px;
    /* left: 86px; */
  }

  #text-1 {
    margin-top: 400px;
  }

  #text-2 {
    margin-top: 376px;
  }

  #text-3 {
    margin-top: 376px;
  }

  #text-4 {
    margin-top: 376px;
  }

  #text-5 {
    margin-top: 376px;
  }

  #text-6 {
    margin-top: 370px;
  }

  #text-7 {
    margin-top: 400px;
  }
}

.laptop-display {
  display: block;
}

.mobile-display {
  display: none;
}

.closed-sidebar.fixed-sidebar .app-main__outer {
  padding-left: 0px;
}

@media (min-width: 510px) and (max-width : 650px) {
  .customer_images {
    width: 100%;
  }

  .row-margin {
    margin: 0rem 2rem;
    flex-wrap: wrap;
  }

  .row-margin2 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .heading-div .row h1 {
    font-size: 38px;
  }


  #bharat {
    width: 78% !important;
    height: 82px !important;
  }

  #lg {
    width: 60%;
    height: 50px !important;
  }

  #cma {
    width: 93% !important;
    height: 100px !important;
  }

  #dadri {
    width: 93%;
    height: 50px !important;
  }

  #aditya {
    width: 83% !important;
    height: 68px !important;
  }
  #myVideo, #myVideo1 {
    position: relative;
    right: 0;
    bottom: 0;
    height: 80vh;
    width: 100%;
    object-fit: fill;
    top:70px;
  }
}

/* mobiles small to large */
@media (min-width: 320px) and (max-width : 425px) {

  .row-margin {
    margin: 0rem 2rem !important;
  }

  #myVideo, #myVideo1 {
    position: relative;
    right: 0;
    bottom: 0;
    height: 80vh;
    width: 100%;
    object-fit: fill;
    top:55px;
  }

  .row-margin2 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .heading-div .row h1 {
    font-size: 26px !important
  }


  #bharat {
    width: 95% !important;
    height: 55px !important;
  }

  #lg {
    width: 90% !important;
    height: 30px !important;
  }

  #cma {
    width: 100% !important;
    height: 55px !important;
  }

  #dadri {
    width: 93%;
    height: 40px !important;
  }

  #aditya {
    width: 83% !important;
    height: 50px !important;
  }
}

@media screen and (max-width: 1150px) {

  .flex-row-reverse{
    justify-content: space-between;
  }
  #scrollDiv{
    display: none;
   }
   .graphBox{
    display: none !important;
   }
.wlcm {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 20%;
  display: flex;
  justify-content: space-around;
}
.wlcm_{
  position: absolute;
  left: 0%;
  right: 0%;
  top: 12%;
  display: flex;
  justify-content: space-around;
}
.wlcm1{
   letter-spacing: 3px !important;
  line-height: 1.2;
  font-size: 55px;
  color: #ff7800; 
  font-weight: 0;
}

.wlcm2{
  font-family: "Fjalla One", sans-serif !important;
  letter-spacing: 0px !important;
  line-height: 1.2;
  font-size: 60px;
  color: #ff7800; 
  font-weight: bold;
}
.nxt{
  width:10%
}
}
/* tabs */
@media screen and (max-width: 950px) {

  .customer_images {
    width: 100%;
  }
  .wlcm_{
    position: absolute;
    left: 0%;
    right: 0%;
    top: 12%;
    display: flex;
    justify-content: space-around;
  }

  .row-margin {
    margin: 0rem 6rem;
  }

  .row-margin2 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .heading-div1 .row #more_3500, #more_3500 h1 {
    font-weight: 900;
    font-size: 26px;
    color: #000;
  }


  #bharat {
    width: 110%;
    height: 105px;
  }

  #lg {
    width: 60%;
    height: 70px;
  }
  .btn-orange{
    height:30px;
  }
  .flex-row{
    justify-content: space-around;
  }
  .nxt{
    width:15%
  }

  #cma {
    width: 71%;
    height: 140px;
  }

  #dadri {
    width: 93%;
    height: 80px;
  }

  #aditya {
    width: 76%;
    height: 100px;
  }


  .laptop-display {
    display: none;
  }

  .mobile-display {
    display: block;
  }

  .vid-display {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }

  .closed-sidebar.fixed-sidebar .app-main__outer {
    padding-left: 0px;
  }

  .nav1 {
    padding: 0rem;
    margin-right: 0px;
    /* margin-right: 1.5rem; */
  }

  .welcome-section {
    width: 100%
  }

  .welcome-text {
    color: white;
    font-weight: bold;
    font-size: 5vh;
    font-family: "Fjalla One", sans-serif;
    text-align: center;
    line-height: 1.2;
  }

  .welcome-login {
    width: 180px;
    background-color: #fff;
    border: medium none;
    border-radius: 100px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
    color: #3f345f;
    height: 48px;
    font-size: 24px;
    font-family: "Fjalla One", sans-serif;

    margin-top: 24px;
  }

  .welcome-register {
    width: 180px;
    border: 1px solid white;
    border-radius: 100px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
    height: 48px;
    font-size: 24px;
    font-family: "Fjalla One", sans-serif;

    margin-top: 24px;
    background: #ff7800;
    color: white;
    margin-right: 24px;
  }

  .feature-head {
    font-size: 30px;
    padding: 1rem 0rem 1rem 3rem;
    font-family: "Fjalla One", sans-serif;

    cursor: pointer;
  }

  .feature-detail {
    text-align: justify;
    font-size: 14px;
    width: 80%;
    margin-left: 6vh;
  }

  .arrow {
    position: relative;
    transform: translateY(30%);
    margin-left: 2vh;

  }

  .color {
    color: #ff7800;

  }

  .feature-detail1 {
    text-align: justify;
    font-size: 14px;
    width: 100%;
  }

  .feature-img {
    width: 50%;
    margin-top: 0px;
  }

  .section-two {
    width: 100%;
    height: 0%;
  }

  .feature-left-inner {
    margin-top: 0rem;
  }

  #hardware-image {
    width: 100%;
    height: 75vh;
  }

  .big-circle {
    width: 200px;
    height: 200px;
    top: 36%;
    left: 36%;
    /* display: flex; */
    /* justify-content: center; */
    line-height: 180px;
    z-index: 1;
  }

  .big-circle-text {
    background-color: #3f345f;
    font-size: 35px;
    color: #ff7800;
    top: 10px;
    font-family: "Fjalla One", sans-serif;

    text-shadow: 1px 1px 3px #000000cf;
    position: relative;
  }

  .small-cirlce {
    width: 100;
    height: 100px;
    width: 100px;
    cursor: pointer;
    z-index: 1;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
  }

  .d2 {
    width: 100%;
  }

  .google {
    padding-left: 0rem;
    padding-top: 0.5rem;
  }

  .apple {
    padding-left: 0rem;
    padding-top: 0.5rem;
  }

  .d3 {
    padding: 0px;
  }

  .d-check {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .trial-text {
    font-size: 18px;
    width: 200px;
    background-color: #fff;
    border: medium none;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
    color: #3f345f;
    height: 36px;
    font-family: "Fjalla One", sans-serif;

  }

  .welcome-txt {
    font-size: 60px;
    font-family: "Fjalla One", sans-serif;
    text-align: center;
    line-height: 1.2;


  }

  .trial-text {
    margin-left: 0;
  }

  #myVideo, #myVideo1 {
    position: relative;
    right: 0;
    bottom: 0;
    height: 65vh;
    width: 100%;
    object-fit: fill;
  }
/* 
  .wlcm {
    font-family: "Fjalla One", sans-serif;

    line-height: 1.2;
    position: absolute;
    left: 5%;
    right: 5%;
    top: -3%;
    font-size: 60px;
    color: #ff7800;
    font-weight: bold;
  } */
  .wlcmtop{
    position: absolute;
    left: 17%;
    right: 10%;
    top: 26%;
    /* display: flex; */
    /* justify-content: space-around; */
  }
  .wlcm1{
    letter-spacing: 3px !important;
   line-height: 1.2;
   font-size: 49px;
   color: #ff7800; 
   font-weight: 0;
 }
 
 .wlcm2{
   font-family: "Fjalla One", sans-serif !important;
   letter-spacing: 0px !important;
   line-height: 1.2;
   font-size: 54px;
   color: #ff7800; 
   font-weight: bold;
 }

  .morevis {
    font-size: inherit;
  }

  .customer_div, .customer_div1 {
    width: 100%;
    position: absolute;
    justify-content: center;
  }
  .m{
    margin-left: 25vh;
    text-align: center;
  }

}

@media screen and (max-width: 800px) {
  /* .wlcm {
    position: absolute;
    top: 10px;
    font-weight: 900;
    font-family: "Fjalla One", sans-serif;
    letter-spacing: 0 !important;
    background-image: linear-gradient(#ff7800, #ff7800);
    background-repeat: no-repeat;
    line-height: 110px;
    background-size: 0 4px;
    background-position: bottom;
  } */
.m{
  margin-left: 20vh;
  text-align: center;

}
.wlcm_{
  position: absolute;
  left: 0%;
  right: 0%;
  top: 12%;
  display: flex;
  justify-content: space-around;
}
  .wlcmtop {
    position: absolute;
    left: 18%;
    top: 24%;
    /* display: flex; */
    /* justify-content: space-around; */
  }

  .wlcm1{
    letter-spacing: 3px !important;
   line-height: 1.2;
   font-size: 39px;
   color: #ff7800; 
   font-weight: 0;
 }
 
 .wlcm2{
   font-family: "Fjalla One", sans-serif !important;
   letter-spacing: 0px !important;
   line-height: 1.2;
   font-size: 42px;
   color: #ff7800; 
   font-weight: bold;
 }


  /* laptops */
  @media (min-width: 1024px) and (max-width : 1440px) {

    .customer1 {
      height: 80vh;
    }

    .row1, .row2 {
      margin: 1rem !important;
    }


    #bharat {
      width: 90%;
      height: 110px !important;
    }

    #pepfules {
      width: 90%;
      height: 50px;
    }
  }

}



@media screen and (max-width: 700px) {
  .m{
    margin-left: 20vh;
    text-align: center;

  }
  .wlcm {
    position: absolute;
    left: 10%;
    right: 10%;
    top: 17%;
    /* display: flex; */
    /* justify-content: space-around; */
  }
  .wlcm_ {
    position: absolute;
    left: 10%;
    right: 10%;
    top: 9%;
    z-index: 9999;
    /* display: flex; */
    /* justify-content: space-around; */
  }
  .wlcmtop {
    position: absolute;
    left: 20%;
    top: 22%;
    /* display: flex; */
    /* justify-content: space-around; */
  }

  
  .wlcm1{
    letter-spacing: 3px !important;
   line-height: 1.2;
   font-size: 38px;
   color: #ff7800; 
   font-weight: 0;
 }
 
 .wlcm2{
   font-family: "Fjalla One", sans-serif !important;
   letter-spacing: 0px !important;
   line-height: 1.2;
   font-size: 40px;
   color: #ff7800; 
   font-weight: bold;
 }
}


@media screen and (max-width: 600px) {
  .m{
    margin-left: 15vh;
    text-align: center;

  }
  .wlcmtop {
    position: absolute;
    left: 16%;
    top: 21%;
    /* display: flex; */
    /* justify-content: space-around; */
  }

  
  .wlcm1{
    letter-spacing: 3px !important;
   line-height: 1.2;
   font-size: 38px;
   color: #ff7800; 
   font-weight: 0;
 }
 
 .wlcm2{
   font-family: "Fjalla One", sans-serif !important;
   letter-spacing: 0px !important;
   line-height: 1.2;
   font-size: 40px;
   color: #ff7800; 
   font-weight: bold;
 }
 .nxt{
   width:40%
 }
 #scrollDiv{
  display: none;
 }
}


@media screen and (max-width: 500px) {
  .wlcmtop {
    position: absolute;
    left: 15%;
    top: 19%;
    /* display: flex; */
    /* justify-content: space-around; */
  }

  #myVideo, #myVideo1 {
    position: relative;
    right: 0;
    bottom: 0;
    height: 80vh;
    width: 100%;
    object-fit: fill;
    top:40px;
  }

  
  .wlcm1{
    letter-spacing: 3px !important;
   line-height: 1.2;
   font-size: 32px;
   color: #ff7800; 
   font-weight: 0;
 }
 
 .wlcm2{
   font-family: "Fjalla One", sans-serif !important;
   letter-spacing: 0px !important;
   line-height: 1.2;
   font-size: 37px;
   color: #ff7800; 
   font-weight: bold;
 }
 .m{
   margin-left: 10vh;
   text-align: center;

 }
  .welcome-txt {
    font-family: "Fjalla One", sans-serif !important;
    line-height: 1.2;
    position: absolute;
    top: 20%;
    left: 4%;
    font-size: 40px;
  }

  #myVideo, #myVideo1 {
    position: relative;
    right: 0;
    bottom: 0;
    height: 55vh;
    width: 100%;
    object-fit: fill;
  }

  .welcome-register {
    width: 15vh;
    border: 1px solid white;
    border-radius: 100px;
    box-shadow: 0 4px 9px 0 rgb(0 0 0 / 20%);
    height: 5vh;
    font-size: 18px;
    font-family: "Fjalla One", sans-serif !important;
    background: #ff7800;
    color: white;
  }

  .welcome-login {
    width: 15vh;
    background-color: #fff;
    border: medium none;
    border-radius: 100px;
    box-shadow: 0 4px 9px 0 rgb(0 0 0 / 20%);
    color: #3f345f;
    height: 5vh;
    font-size: 18px;
    font-family: "Fjalla One", sans-serif !important;
  }

  .feature-left-inner {
    margin-top: -2rem;
  }

  .btntop{
    margin-top:5vh;
    display: flex;
    justify-content: center;
  }
  
  .btntop1{
    margin: 1vh;
    padding: 1vh;
    width: 35%;
  }
}

.contactUs:hover{
  cursor:pointer;
  /* text-decoration: underline; */
}
.contactUs{
  text-decoration: underline;
}


/* .wlcm {
  width: 30vw; 
  transition: width 0.5s ease;
}
.wlcm.wider {
  width: 60vw; 
} */

.error-sign {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.login-btn_:hover{
  cursor: pointer;
}

label{
  font-weight: 500;
  color:#808080;
}
.col-md-3{
  padding-left: 0;
  padding-right: 0;
}

.btn-gray{
  background-color: #FFFFFF;
  color:#000000
}

.zoomed-svg {
  transform: scale(2.3); /* Adjust the scale value as needed */
}
.flexcolBox{
  display: flex;
  flex-direction: column;
}
.imgIcn{
  font-size: 16px;
  cursor: pointer;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 6px;
  height: 6px;
  margin-left: 30px; 
  margin-bottom: 18px; 
}
.lds-spinner div {
  transform-origin: 6px 6px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 2px;
  left: 18px;
  width: 2px;
  height: 6px;
  border-radius: 20%;
  background: #000000;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* tripDetailsBox Dashboard********** */

.tripDEtailsBox{
  width: 100%;
  position: relative;
  overflow-x: auto;
}
.button-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center; 
  align-items: flex-start;
  top: -10px;
  z-index: 100;
}
.comp-container{
  position: absolute;
  width: 100%;
}
.slidingContainer{
  display:  flex;
  align-items: center;
  width: 96%;
  max-width: 96%;
  overflow-x: auto;
  gap: 10px;
}
.arrowBox{
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrowClass{
  font-size: 2.2rem !important;
}
.arrowClass:hover{
  color: #ff7200;
  cursor: pointer;
}
.pviaBox{
    width: 300px;
    height: 145px;
    border: 1px dashed #B8B8B8	;
    border-radius: 12px;
    box-shadow: 0px 15px 10px -15px #787878; 
}
.pviaBox:hover{
  width: 300px;
  height: 145px;
  border: 1px dashed #5c5c5c	;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 2px 2px 11px  #787878 ; 
}
.pViaCont{
  width: auto;
  height: 145px;
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
 
}
.pViaDots{
  width: 150px;
  height: 2px;
  position: relative;
  margin-left: 7px;
  margin-right: 7px;
}
.pArrDot{
  position: absolute;
  right: -10px;
  top: -12px;
  font-size: 16px;
}
.pDistance{
    position: absolute;
    top: -24px;
    left: 20%;
    font-size: 12px;
    font-weight: 600;
}
.pTime{
  position: absolute;
  top: 5px;
  left: 20%;
  font-size: 12px;
  font-weight: 600;
}
.pAddress{
  width: 100%;
  height: 26%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}
.pTimeBox{
  width: 100%;
  height: 67%;
}
.pSt{
  width: 100%;
  height: 23%;
  display: flex;
  margin-bottom: 1.5px;
}
.pstLabelValue{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.pstLabel{
  padding-right: 10px;
  font-weight: 700;
  font-size: 13px;
}
.pstValue{
  width: 60%;
  font-weight: 550;
  font-size: 13.5px;
}
.trip_doc_upload_box{
  width: 250px;
  height: 187px;
  border: 2.5px dashed;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.imgContainer{
  width: 250px;
  height: 90%;
  border-radius: 10px;
  border: 1px solid #cfcfcf;
  padding: 8px
}
.imgContainer:hover{
  box-shadow: 0px 0px 10px #d3d3d3;
}
.crossBox{
  position: absolute;
  top: -4px;
  right:2px;
}
.closeIClass{
  font-size: 15px !important;
  cursor: pointer;
  color: #636262;
}
.closeIClass:hover{color: #000;}

.imgBox{
  width: 100%;
  height: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.imgCss--doc-upload{
  width: auto;
  height: 90%;
}
.imgCss--doc-upload:hover{
  cursor: pointer;
  transform: scale(1.1)
}
.imgDetails{
  width: 100%;
  height: 45%;
  margin-top: 10px;
}
.imgDr{
  width: 100%;
  display: flex;
  min-height: 15px;
}
.imgDrLabel{
  width: 40%;
  font-size: 11px;
  font-weight: 700;
  padding-left: 7px;
}
.imgDrValue{
  width: 60%;
  font-size: 12px;
  padding-left: 4px;
  white-space: nowrap; /* or 'pre-wrap' for preserving line breaks */
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}
.iframe-camera{
  width: 100%;
  height: 100%;
}
.t-menu-item {
  color: black;
}


.drawer-header-container{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 8px;
  min-height: 65px;
}

@media screen and (max-width: 899px){
  .drawer-header-container{
    min-height: 10px;
  }
}