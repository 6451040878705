.legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0px;
    font-size: 12px;
    font-weight: bold;
    margin-left: 0px;
}

.legendItem {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.legendColor {
    width: 15px;
    height: 15px;
    margin-right: 8px;
}

.tooltip {
    position: absolute;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    pointer-events: none;
    opacity: 0;
    font-size: 12px;
    font-family: Arial, sans-serif;
}

.donut-middle-title{
    max-width: 150px !important;

}