body {
    scrollbar-width: thin;
}

.roundedRow :first-child {
    border-top-left-radius: "12px";
}
.roundedRow :last-child {
    border-top-right-radius: "12px";
}
.MuiOutlinedInput-root{
    border-radius: 12px !important;
}

.MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #FF7200
}