@import "./variables";
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5em;
}
.title {
  display: flex;
  align-items: center;
  // gap: 0.15em;
  gap: 0.25em;
  h3{
    margin: 0;
  }
}
.panel {
  display: flex;
  gap: 1em;
}
.main_content {
	display: grid;
	grid-template-rows: 1fr;
	// grid-template-columns: 100% 0;
	grid-template-columns: 1fr auto;

	grid-template-areas: "dashboard panel";
	gap: 0.5em;
}
.asset_management_dashboard {
	grid-area: dashboard;
	// padding-right: 1em;
	height: 80vh;
	overflow: auto;
}

.asset_management_detail_panel {
	grid-area: panel;
	// width: 0px;
	width: 0px;
	transition: all 1s;
	border: 1px solid;
	border-radius: 0.2rem;
	height: 80vh;
	overflow: hidden;

	.panel__header {
		background-color: $dark-card-background;
		color: $semi-dark;
		border-top-left-radius: 0.2rem;
		border-top-right-radius: 0.2rem;

		font-size: 1rem;
		font-weight: bold;

		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 0.5em;
		padding: 0.25em 0;
		position: relative;
		overflow: hidden;
		// width: 100%;
		width: 350px;

		.close_btn {
			position: absolute;
			right: 20px;
			top: 3px;
			width: 25px;
			height: 25px;
			opacity: 0.5;
			cursor: pointer;
			transition: all 0.2s;

			&:hover {
				opacity: 1;
			}

			&::before,
			&::after {
				position: absolute;
				left: 15px;
				content: " ";
				height: 20px;
				width: 2px;
				background-color: $semi-dark;
			}

			&::before {
				transform: rotate(45deg);
			}

			&::after {
				transform: rotate(-45deg);
			}
		}
	}

	.detail_panel {
		// border: 1px solid $dark-card-border;
		// border-radius: 0.2rem;
		// width: 100%;
		width: 350px;
		height: 75vh;
		overflow: auto;
		// padding: 1em;
		&__card {
			border: 1px solid;
			border-radius: 0.2rem;
			margin: 0.5em;
			padding: 0.5em;
		}

		&__row {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			gap: 2em;
			font-size: 14px;
		}

		&__col {
			flex-basis: 50%;
			display: flex;
			gap: 0.25em;
			align-items: center;

			i {
				font-size: 0.9rem;
				flex-basis: 20%;
				color: $primary-color;
			}

			+ .detail_panel__col {
				display: block;
				// flex-wrap: wrap;
				flex-basis: 50%;
				position: relative;

				&::before {
					content: ":";
					position: absolute;
					left: -10px;
				}

				.address {
					color: $primary-color;
					cursor: pointer;
					transition: all 0.2s;
					padding: 0px !important;

					&:hover {
						color: $primary-color--light;
					}
				}
			}
		}
	}

	.categories_container {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 0 1em;
		justify-content: center;
		gap: 0.5em;

		.category {
			background: $dark-card-inbox;
			padding: 0.25em 0.5em;
			border-radius: 0.2rem;
		}
		.react-datepicker-wrapper input {
			width: 100%;
		}
	}
}

.asset_management_detail_panel.open {
	width: 350px;
}
.main_asset_wrap {
	display: flex;
	justify-content: space-between;
	// flex-wrap: wrap;
	gap: 1em;
}

.asset_table,
.asset_chart {
	height: 250px;
	border: 1px solid;
	// border: 1px solid $dark-card-border;
	border-radius: 0.2rem;
	overflow-y: auto;
}

.asset_table {
	flex-basis: 30%;
	// flex-grow: 1;
	// overflow-x: scroll;

	.table-responsive {
		height: 100%;

		.table {
			height: 100%;
		}
	}

	td {
		// color: $dark-all-font-color !important;
		vertical-align: middle;

		.asset_count {
			background-color: $primary-color;
			padding: 0.25em;
			border-radius: 0.2rem;
			cursor: pointer;
			transition: all 0.2s ease-out;

			&:hover {
				background-color: $primary-color--accent;
			}
		}

		+ td {
			width: 30%;
			padding: 0.5rem 1rem;
		}
		.self_asset_count {
			background-color: #696969;
			padding: 0.25em;
			border-radius: 0.2rem;
			cursor: pointer;
			transition: all 0.2s ease-out;

			&:hover {
				background-color: #696969;
			}
		}

		+ td {
			width: 30%;
			padding: 0.5rem 1rem;
		}
	}
}

.asset_chart {
	position: relative;
	// width: 650px;
	width: 68%;
	padding: 1em;
}

// categories (vendors)
.categories {
	margin-top: 1.75em;
	padding: 0;

	&__header {
		// color: $theme-font-color;
		// background-color: $primary-color;
		// background-color: $dark-card-background;
		// background-color:  $dark-card-border;
		border: 1px solid $dark-card-border;
		border-radius: 0.2rem;

		display: flex;
		align-items: center;
		// justify-content: space-between;

		padding: 0.5em 0.75em;
	}

	&__title {
		margin: 0;
	}

	&__action {
		display: flex;
		align-items: center;
		gap: 1em;
	}

	.action__btn {
		color: $dark-all-font-color;
		background-color: $dark-card-background;
		border: 1px solid $dark-card-border;
		border-radius: 0.2rem;

		font-size: $btn-font-size;
		font-weight: bold;

		display: flex;
		align-items: center;
		justify-content: center;

		padding: 0.25em 0.75em;
		height: 35px;

		transition: all 0.2s ease-out;

		&:hover {
			background-color: $dark-body-background;
		}
	}

	&__download {
		cursor: pointer;

		&__icon {
			fill: $dark-all-font-color;

			height: 30px;
			width: 30px;
		}
	}

	&__selectAll {
		label,
		input[type="checkbox"] {
			margin: 0;
			cursor: pointer;
		}

		input[type="checkbox"] {
			/* Add if not using autoprefixer */
			-webkit-appearance: none;
			appearance: none;
			/* For iOS < 15 to remove gradient background */
			background-color: #fff;
			/* Not removed via appearance */
			color: currentColor;
			width: 1em;
			height: 1em;
			border-radius: 0.2em;
			transform: translateY(-0.075em);

			display: grid;
			place-content: center;

			&::before {
				content: "";
				width: 0.65em;
				height: 0.65em;
				transform: scale(0);
				transition: 120ms transform ease-in-out;
				box-shadow: inset 1em 1em $primary-color;
				transform-origin: bottom left;
				clip-path: polygon(
					14% 44%,
					0 65%,
					50% 100%,
					100% 16%,
					80% 0%,
					43% 62%
				);
			}

			&:checked {
				&::before {
					transform: scale(1);
				}
			}
		}

		label {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 0.25em;
		}
	}
}

.categories__cards {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	gap: 1em;
	margin-top: 1em;

	.no_data {
		text-align: center;
		font-size: 1.5rem;
	}
}

.categoryCard {
	border: 1px solid;
	border-radius: 0.2rem;

	&__title {
		// background-color: $dark-card-background;
		// color: $semi-dark;
		border-top-left-radius: 0.2rem;
		border-top-right-radius: 0.2rem;

		font-size: 1rem;
		font-weight: bold;

		display: flex;
		justify-content: space-between;
		align-items: center;

		padding: 0.7em;

		.checked {
			box-shadow: 0 3px 10px $dark-border-color;
		}

		input[type="checkbox"] {
			/* Add if not using autoprefixer */
			-webkit-appearance: none;
			appearance: none;
			/* For iOS < 15 to remove gradient background */
			background-color: $semi-dark;
			/* Not removed via appearance */
			color: currentColor;
			width: 1.5em;
			height: 1.5em;
			border-radius: 50%;
			transform: translateY(-0.075em);

			display: grid;
			place-content: center;

			&::before {
				content: "";
				width: 0.9em;
				height: 0.9em;
				border-radius: 50%;
				transform: scale(0);
				transition: 120ms transform ease-in-out;
				box-shadow: inset 1em 1em $primary-color;
			}

			&:checked {
				&::before {
					transform: scale(1);
				}
			}
		}
	}

	&__body {
		background-color: $card-body-bg-color;
		border-bottom-left-radius: 0.2rem;
		border-bottom-right-radius: 0.2rem;
		color: $dark-all-font-color;
		padding: 1em;

		height: 290px;
		overflow: auto;
	}

	&__row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 1em;
		margin-bottom: 0.5em;
	}

	&__col {
		flex-basis: 70%;
		word-break: break-all;

		+ .categoryCard__col {
			flex-basis: 20%;
			// background-color: $primary-color;
			text-align: center;
			border-radius: 0.2rem;
			cursor: pointer;
			transition: all 0.2s ease-out;
			position: relative;

			// &:hover {
			// 	background-color: $primary-color--accent;
			// }
		}
	}
}

// .item_selected {
// 	// background-color: $body-color !important;
// 	// color: $primary-color !important;
// 	// font-weight: bold;
// }

.btn-blue {
	background-color: #007bff !important;
	border-color: #007bff !important;
}

.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active,
.btn-blue.active {
	background-color: #007bff !important;
	border-color: #007bff !important;
	opacity: 0.7;
}

.btn-blue.disabled,
.btn-blue:disabled {
	background-color: #007bff !important;
	border-color: #007bff !important;
}

.panel {
	gap: 1em !important;
}

.react-datepicker .react-datepicker__day--today {
	background-color: #302d2d !important;
}

.header {
	margin-bottom: 0;
}
.table-responsive {
    display: block;
    width: 100%;
     overflow-x: hidden; 
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch;
}
