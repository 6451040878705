.panel {
    // border: 1px solid #374558;
    // border-radius: 0.2rem;
}

.grid {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 80% 20%;
    grid-template-areas: "dropable draggable";
    gap: 0.5em;
}

.item1 {
    grid-area: "header";
    grid-column: 1/3;
    background-color: #262932;
    padding: 10px 5px;
}

.item2 {
    grid-area: "dropable";
}

.item3 {
    grid-area: "draggable";
    height: 385px !important;
    overflow-y: scroll;
}

.scrol {
    scroll-behavior: smooth;
}

.grid>div {
    border: 1px solid #374558;
    height: fit-content;
    text-align: center;
}

.header_border {
    border: 1px solid #F57C00CC !important;
}

.dnd_border {
    border-style: dashed;
    border-width: 1.5px;
    border-color: #A9A9A9;
    border-radius: 5px;
    margin: 7px;
    width: 100%;
}

.hclass:hover{
    border: 1px solid #ff7200;
}

.colmn {
    // box-shadow: 0 0px 4px 1px #374558, 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
    border-radius: 16px;
}

.dnd_border>div {
    width: 100%;
}

button.dnd_border {
    width: 96% !important;
  
}


table th {
    font-size: 16px;
}
.tableFont th{
    text-align: center;
    font-size: 13px !important;
    padding: 15px;
    font-weight:600 !important;
    vertical-align:middle
}
.cursor_move
{
    cursor: move !important;
}

btn:disabled
{
	cursor: not-allowed ;
}
.colInp{
    min-width: 180px;
    border: none;
    outline: none;
    font-size: 14px;
}
.colInp::placeholder{
    color: #707070;
    font-size: 12px;
}
.colInp:focus{
    outline: none;
}
::-webkit-scrollbar {
  height: 4px;              /* height of horizontal scrollbar ← You're missing this */
  width: 4px;               /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}