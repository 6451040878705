/* font family */
.font-Nunito{
    font-family: 'Nunito';
}


.mapBackground {
    background-image: url('../images/mapImage/IWS_Stall_2023_v1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
    background-color: #fff;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(142,142,142,.2),0 1px 1px 0 rgba(243,243,243,.14),0 2px 1px -1px rgba(204,204,204,.12)!important;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 16px;
    min-height: 16px;
    max-width: calc(100% - 32px);
    max-height: calc(100% - 32px);
    outline: 0;
}
/* font size */
.fs-2{
    font-size: 2px;
}
.fs-4{
    font-size: 4px;
}
.fs-6{
    font-size: 6px;
}
.fs-8{
    font-size: 8px;
}
.fs-10{
    font-size: 10px;
}
.fs-12{
    font-size: 12px;
}
.fs-14{
    font-size: 14px;
}
.fs-16{
    font-size: 16px;
}
.fs-18{
    font-size: 18px;
}
.fs-20{
    font-size: 20px;
}
.fs-22{
    font-size: 22px;
}
.fs-24{
    font-size: 24px;
}


/* font-weight */

.fw-600{
    font-weight: 600;
}

/* color */
.orange-color{
    color: #FF7200;
}

/* background color */

.orange-bg{
    background: #FF7200;
}

/* text styling */

.center-text {
    text-align: center;
}

.white-text{
    color: #fff !important;
}

/* remove scroll bar */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */


  /*Grid css close*/

.grid-section {
    min-width: 100px !important;
    min-height: 100px;
  }
  
  .grid-section table,
  .grid-section td,
  .grid-section th {
    border: 1px solid black;
  }
  
  .grid-section table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .grid-section td {
    text-align: center;
    vertical-align: middle;
    padding: 4px;
    width: 45px;
  
}
.MuiInputBase-input {
    color: #808080 !important;
    font-size: .85rem!important;
    font-weight: 500!important;
}
.crossIconPri{
    display: inline-block;
    justify-content: flex-end;
    align-items: end;
    position: absolute;
    right: 0px;
    cursor: pointer;
}
.fw-700 {
    font-weight: 700;
  }

.bg-tempting-success{
    color: green;
    margin-top: 5px;
}
.bg-tempting-warning{
    color:red;
    margin-top: 5px;
}
.bg-tempting-dark{
    color: #000;
    margin-top: 5px;
 }
.bg-tempting-info{
    color: yellow;
    margin-top: 5px;
}
.bg-tempting-defautlt{
    color: blue;
    margin-top: 5px;
}
.badge{
    color:#000 ! important;
}
iframe#webpack-dev-server-client-overlay{display:none!important}