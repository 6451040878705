.img-container--outer{
  height: 100%;
  width: 100%;
  max-height: 270px;
  max-width: 270px;
}
.img-container--outer img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}