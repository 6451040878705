@import "./variables";

// monitoring dashboard
#monitoring-dashboard__type-filter {
	color: var(--clr--primary) !important;
}

.mini_loader {
	.loader_img {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		opacity: 0.5;
		z-index: 1;
	}
}

.monitoring_wrapper {
	.dashboard {
		display: grid;
		grid-template-rows: 1fr;
		grid-template-columns: 1fr auto;
		grid-template-areas: "dashboard side";
		gap: 0.5em;
		&__main_content {
			grid-area: dashboard;
			margin-top: 1em;
			height: 81vh;
			overflow: auto;
			.card:last-child {
				margin: 0 !important;
			}
		}
		&__side_content {
			grid-area: side;
			width: 0;
			transition: all 0.2s ease-in;
			border: 1px solid $dark-card-border;
			border-radius: 0.2rem;
			height: 81vh;
			overflow: hidden;
			margin-top: 1em;
			position: relative;
			&.open {
				width: 375px;
			}
			.detail_panel {
				height: 100%;
				// overflow: auto;
				&__header {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 1em;
					position: relative;
					padding: 0.5em;
					margin: 0 0.5rem;
					&__left {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 1em;
						&__text {
							font-size: 16px;
						}
						&__value {
							padding: 0.25em 0.75em;
							border-radius: 12px;
						}
					}

					.excelIcon {
						right: 0;
					}
					&__right {
						display: flex;
						align-items: center;
						gap: 1em;
						.close_btn {
							position: relative;
							width: 25px;
							height: 25px;
							opacity: 0.5;
							cursor: pointer;
							transition: all 0.2s;

							&:hover {
								opacity: 1;
							}
							&::before,
							&::after {
								position: absolute;
								left: 15px;
								content: " ";
								height: 20px;
								width: 2px;
								background-color: $semi-dark;
							}

							&::before {
								transform: rotate(45deg);
							}

							&::after {
								transform: rotate(-45deg);
							}
						}
					}
				}
				&__search_wrap {
					margin: 0 1em 0.5em 1em;
				}
				&__body {
					height: 70vh;
					overflow: auto;
				}
				&__card {
					border: 1px solid $dark-card-border;
					border-radius: 0.2rem;
					margin: 0px 1em;
					// margin: 0.25em 1em 1em 1em;
					padding: 1em;
					position: relative;
					.star_mark {
						position: absolute;
						top: 0.25rem;
						right: 0.25rem;
						cursor: pointer;

						.star_mark_icon {
							height: 1rem;
							width: 1rem;
						}
						.fill {
							fill: $primary-color;
						}
					}
				}

				&__row {
					display: flex;
					justify-content: space-between;
					gap: 2em;
					font-size: 11px;
				}

				&__col {
					flex-basis: 35%;
					display: flex;
					gap: 0.25em;
					align-items: center;

					i {
						font-size: 0.9rem;
						flex-basis: 20%;
						color: $primary-color;
					}

					+ .detail_panel__col {
						display: block;
						// flex-wrap: wrap;
						flex-basis: 60%;
						position: relative;

						&::before {
							content: ":";
							color: white;
							position: absolute;
							left: -10px;
						}

						.address {
							color: $primary-color;
							cursor: pointer;
							transition: all 0.2s;

							&:hover {
								color: $primary-color--light;
							}
						}
					}
				}
			}
		}
	}
}
.highlight {
	color: #ff7200;
	cursor: pointer;
}
.btn_primary {
	border: 1px solid $primary-color;
	background-color: var(--clr--primary-accent);
	color: rgba(255, 255, 255, 0.9);
	border-radius: 0.2rem;
	transition: all 0.2s ease;
}
.btn_primary:hover {
	background-color: $primary-color;
}
// for now, it has been decided that all icons will be orange by default
.monitoringDashboard-tabs {
	border: 1px solid var(--clr--darkgrey);
	border-radius: 4px;
	// color: var(--clr--darkgrey);
	background-color: #FF7200;
	color: rgba(255, 255, 255, 0.7);
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 2rem;
	width: 2rem;
}
.svg-icon {
	max-width: 100%;
	max-height: 100%;
}
.monitoringDashboard-tabs:hover,
.mapIcon-active {
	background-color: var(--clr--primary-accent);
	// background-color: var(--clr--primary);
	// fill: #fff;
	// color: #fff;
}

.monitoringDashboard-tabs__icon {
	max-width: 100%;
	max-height: 100%;
	fill: rgba(255, 255, 255, 0.7);

	&.excelIcon {
		height: 1.2em;
		width: 1.2em;
	}
}
// .monitoringDashboard-tabs__icon

.monitoringDashboard__map-container {
	transition: all 0.3s ease-in;
}

.monitor-settings-modal .modal-header {
	text-align: center;
}
.monitor-settings-modal .modal-title {
	width: 100%;
}

.monitoring_comment_modal_header .modal-title {
	flex-basis: 90%;
	display: flex;
	align-items: center;
	gap: 0.75em;
	position: relative;
	// border: 1px solid red;
	.excelSpan {
		position: absolute;
		right: 0;
	}
}

.monitorDashboard__settings-tabs {
	border: 1px solid var(--clr--primary);
	display: flex;
	border-radius: 20px;
	margin: 0.75em 0;
}

.settings__tab {
	// border: 1px solid var(--clr--primary);
	flex-basis: 100%;
	border-radius: 20px;
	margin: 3px;
	cursor: pointer;
	font-size: 0.9rem;
	padding: 0.5em;
}
.settings__tab + .settings__tab {
	margin-left: 0;
}

.settings__tab:hover {
	background-color: var(--clr--primary);
	opacity: 90%;
}

.settingsModal__table {
	border: 1px solid var(--clr--darkblue2);
	width: 100%;
}
.settingsModal__table thead th {
	color: var(--clr--grey);
	background-color: var(--clr--darkgrey);
	border: 1px solid var(--clr--darkblue2);
	padding: 10px 0;
}
.settingsModal__table tbody td {
	color: var(--clr--grey);
	border: 1px solid var(--clr--darkblue2);
}

.monitor-settings-modal .modal-body {
	min-height: 30vh;
	max-height: 60vh;
	overflow-y: auto;
}
.monitor-settings-modal .modal-content {
	border-radius: 12px;
}

.monitor-settings-modal {
	::-webkit-scrollbar {
		width: 0.65em;
		cursor: pointer;
	}
	::-webkit-scrollbar-track {
		border-radius: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: $dark-body-background;
		cursor: pointer;
	}
	::-webkit-scrollbar-thumb:hover {
		background: $dark-card-inbox;
	}
}

.monitorDashboard__section-header {
	border-top-right-radius: 12px;
	border-top-left-radius: 12px;
	padding: 0.25em 0.5em;

	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1em;
	
}
.alert_switch {
	// margin-right: 0.7rem;
	width: fit-content;
	display: flex;
	button {
		color: $light-semi-gray;
		cursor: pointer;
		// width: 9rem;
		transition: all 0.2s ease;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			height: 2rem;
		}
	}
}
// .monitorDashboard__map-resize-icon {
//   position: absolute;
//   right: 0.25rem;
//   bottom: 0.75rem;
//   height: 2rem;
//   width: 2rem;
//   cursor: pointer;
//   border-radius: 4px;
// }

.monitorDashboard__section-body {
	display: grid;
	grid-template-columns: repeat(
		auto-fit,
		minmax(calc(min(100%/4, calc(max(15rem, 100%/6)))), 1fr)
	);
	gap: 1.25em;
}

.sectionBody-box {
	// max-width: 100%;
	padding: 0.5em;
	height: 6em;
	border-radius: 12px;
	transition: all 0.1s ease-out;
	cursor: pointer;
}

.sectionBody-box:hover {
	scale: 1.03;
}

.sectionBody-box .media {
	flex-basis: 65%;
}
.sectionBody-box .media-subtext {
	bottom: 5px;
	color: var(--clr--darkgrey);
}

.sectionBody-box .box__value {
	font-size: 2rem;
	flex-basis: 25%;
}
.sectionBody-box .box__divider {
	display: inline-block;
	width: 2px;
	height: 60px;
	right: 56px;
	// top: 10px;
	left: 0;
	border-radius: 12px;
}

.settingsModal__table .colorPicker {
	height: 20px;
	width: 20px;
	cursor: pointer;
	border: 1px solid black;
	border-radius: 4px;
}

.settingsModal__table .eyeClose-icon,
.settingsModal__table .shift-icon,
.settingsModal__customField .save-icon,
.settingsModal__customField .delete-icon,
.addCustomField-icon,
.settingsModal__table .save-icon,
.commentSection .cross-icon {
	height: 32px;
	width: 32px;
	transition: all 0.1s ease-out;
	fill: var(--clr--grey);
}
.settingsModal__table .eyeOpen-icon,
.settingsModal__table .eyeClose-icon {
	height: 42px;
	width: 42px;
}

.icon-fill-primary {
	fill: var(--clr--primary);
}
.settingsModal__customField {
	width: 98%;
	margin-inline: auto;
	background-color: var(--clr--darkblue2);
}

.settingsModal__customField .save-icon,
.settingsModal__table .save-icon {
	fill: var(--clr--success);
}
.settingsModal__customField .save-icon:hover,
.settingsModal__table .save-icon:hover {
	fill: #48a221;
}
.settingsModal__customField .delete-icon {
	fill: var(--clr--danger);
}
.settingsModal__customField .delete-icon:hover {
	fill: #c51c2d;
}

.monitor-settings-modal .addCustomField-btn,
.monitor-settings-modal .saveDefault-btn {
	margin: 0.75em auto;
}

.commentSection .cross-icon {
	fill: var(--clr--primary);
}
.commentSection:hover .cross-icon {
	fill: var(--clr--primary-accent);
}

.switch-monitoring-modal-header .modal-title {
	font-size: 1rem;
}

.switch-monitoring-list > li {
	cursor: pointer;
	padding: 0.5em;
	border-radius: 8px;
	border: 1px solid var(--clr--darkgrey);
	text-align: center;
	transition: all 0.2s ease-out;
	width: 10rem;
}
.switch-monitoring-list > li:hover {
	background-color: var(--clr--darkgrey);
}
.current-monitoring-type {
	background-color: var(--clr--darkgrey);
}

//comment section monitoring dashboard
.monitoringInfo__modal .modal-content {
	border-radius: 12px;
}
.commentSection {
	border-radius: 12px;
	background-color: $dark-body-background;
}

.headerValue {
	padding: 0.25em 0.75em;
	border-radius: 12px;
	// margin-left: 0.75em;
}

.trackingInfo__details-right,
.trackingInfo__details-left {
	flex-basis: 100%;
	padding: 0.75em;
}

.trackingInfo__details-right {
	border-right: 1px solid var(--clr--darkgrey);
}
.trackingInfo__details-left {
	padding-left: 2em;
}

.trackingInfo__label {
	color: var(--clr--darkgrey);
}

.trackingInfo__location {
	border-top: 1px solid var(--clr--darkgrey);
	border-bottom: 1px solid var(--clr--darkgrey);
	padding: 1.25em 0.75em;
}

.commentSection__input-wrapper {
	padding: 1em 0;
	font-size: 11px;
}

.viewComments,
.addComment {
	border: 1px solid var(--clr--darkgrey);
	margin-bottom: 1em;
	border-radius: 12px;
	background-color: var(--clr--darkblue2);
	cursor: pointer;
	transition: all 0.2s ease-out;
}
.viewComments:hover,
.addComment:hover {
	background-color: $dark-color;
}

.viewComments__heading,
.addComment__heading {
	text-align: center;
	padding: 0.5em;
}

.comments-wrapper,
.commentBox-wrapper {
	background-color: $light-all-font-color;
	padding: 0.5em;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	cursor: default;
}
.commentBox-wrapper > textarea {
	display: block;
	background-color: transparent !important;
	border: none !important;
	padding: 0.25em;
	width: 100%;
	height: 90%;

	border-right: 1px solid;

	/* resize  : none; */
	overflow: auto;
}
.commentBox-wrapper > textarea::placeholder {
	color: $dark-gray;
	font-size: 11px;
}

.btn-resolveIssue,
.btn-addComment {
	border-radius: 12px;
	border: none;
	background-color: $primary-color !important;
	padding: 0.25em 1.5em;
	color: $light-semi-gray;
	transition: all 0.2s ease-out;
}

.btn-resolveIssue:hover,
.btn-addComment:hover {
	background-color: var(--clr--primary-accent) !important;
}

.commentSection__dropdown-icon-wrapper {
	position: absolute;
	right: 1.75em;
	top: 0.75em;
	scale: 1.5;
}

/*

.dashed-line {
  width: 30px;
  border-top: 1px dashed var(--clr--primary);
}
.dashed-line-top,
.dashed-line-bottom {
  height: 2rem;
  width: 1px;
  border-right: 1px dashed var(--clr--grey);
}
.dashed-line-top {
  margin-top: -0.3em;
}
.dashed-line-bottom {
  margin-bottom: -0.3em;
}
.dashed-line-middle-top,
.dashed-line-middle-bottom {
  height: 1.5rem;
  width: 1px;
  border-right: 1px dashed var(--clr--grey);
}
.dashed-line-middle-top {
  margin-bottom: -0.3em;
}
.dashed-line-middle-bottom {
  margin-top: -0.3em;
}
*/

.comments-wrapper {
	display: flex;
	flex-direction: column;
	gap: 1.5em;
	max-height: 200px;
	overflow: auto;
	.comment {
		display: flex;
		justify-content: space-between;
		gap: 0.5em;
		&__text {
			word-break: break-all;
			flex-basis: 60%;
			position: relative;
			transition: all 1s ease-out;
			// display: flex;
			&::before {
				content: "";
				position: absolute;
				border: 2px solid $primary-color;
				border-radius: 50%;
				width: 10px;
				height: 10px;
				top: 4px;
				left: -22px;
				// transform: translateX(-50%);
			}
			&::after {
				content: "";
				position: absolute;
				border-left: 2px solid $primary-color;
				width: 2px;
				height: auto;
				top: 13px;
				bottom: -21px;
				left: -18px;
			}
			&.last {
				&::after {
					height: 0;
				}
			}
		}
	}
}
.comment__line::before {
	content: "";
	position: absolute;
	border: 2px solid $primary-color;
	border-radius: 50%;
	width: 10px;
	height: 10px;
	top: 5px;
	left: 50%;
	transform: translateX(-50%);
}
.comment__line {
	transition: all 1s ease-out;
}
.comment__line::after {
	content: "";
	position: absolute;
	border-left: 2px solid $primary-color;
	width: 2px;
	height: auto;
	top: 15px;
	left: 50%;
	transform: translateX(-50%);
	top: 15px;
	bottom: -15px;
}

.comment:last-child > .comment__line::after {
	height: 0;
}
