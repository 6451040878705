.cardPermissionsScrollable {
    height: 175px !important;
    overflow: hidden;
    padding-bottom: 0.5rem !important;
    padding-top: 0 !important;
}


.cardPermissionsScroll {
    overflow: scroll !important;
    overflow-x: hidden !important;
}

.card{
    position: relative;
}

.buttons {
    position: absolute;
    visibility: hidden;
  }

  .card:hover .buttons {
    visibility: visible;
  }