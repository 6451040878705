// vars ----
$input-bg: #181818;
$label-text-color: #666666;
$theme-orange: #ff7503;
$theme-body-font-color: #2b2b2b;
$body-color: #f8f8f8;

.sign-up {
  min-height: 100vh;
  background: url('../../../assets/images/sign-up/sign-up_bg.png');
  background-position: center;
  background-color: #161515;
  position: relative;
  overflow: hidden;
//#d5c2c2
  // @media screen and (min-width: 1000px) {
  //   overflow-y: hidden;
  // }

  .logo_main{
    height: 3.1rem;
    padding-top: 0.5rem;

    @media only screen and (min-width: 1150px){
      height: 4.2rem;
      max-height: 100vh;
    }
  }

  .hero-img-row{
    height: calc(100vh - 4.3rem);
    position: relative;
  }

  .hero-img-container{
    position: absolute;
    left: 0;
    bottom: 0;
    min-width: 800px;
    width: calc(100% - 23vw);
    max-height: 100%;

    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    // @media screen and (min-width: 500px) {
    //   display: block;
    // }
  }

  .form-card{
    position: absolute;
    padding: 1rem 1.5rem;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    background-color: #1d1d1d;
    box-shadow: 2px 3.5px #101010;
    gap: 0.7rem;
    min-width: 200px;

    @media screen and (min-width: 500px) {
      padding: 1.3rem 2.5rem;
      width: 420px;
    }

    @media screen and (max-width: 618px) {
      width: 90%;
      left: 50%;
      top: 5rem;
      transform: translateX(-50%);
    }

    .signup-error-msg{
      color: red;
      font-size: 12px;
    }

    .text-gray{
      color: $label-text-color;
    }

    .text-orange{
      color: $theme-orange;
    }

    .font-sz-12{
      font-size: 12px;
    }

    .pill{
      background-color: $theme-orange;
      color: $theme-body-font-color;
      padding: 0.1rem 1rem;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 15px;
      text-align: center;
    }

    span{
      color: $body-color;
      font-size: 1.1rem;
    }

    .sign-up-form-control{
      background-color: $input-bg !important;
      border: 1px solid $input-bg;
      box-shadow: 1px 1px #101010;
      font-size: 0.8rem;
    }

    .sign-up-col-form-label{
      // color: $label-text-color;
      color: #d5c2c2;
    }

    .signup-submit-btn{
      background-color: $theme-orange !important;
      border-color: $theme-orange !important;
      color: $theme-body-font-color;
      width: 100%;
      font-size: 1rem;
      font-weight: 500;
    }

    .signup-submit-btn:disabled,
    .signup-submit-btn-outline:disabled{
      background-color: $input-bg !important;
      border-color: $input-bg !important;
      color: $label-text-color;
    }
    
    .signup-submit-btn-outline{
      background-color: transparent !important;
      border-color: $theme-orange !important;
      color: $theme-orange;
    }
  }
}